import { ComponentRendererProps } from '../ComponentRenderer';
import { Bubble } from '../Bubble';
import { PlatformTextRenderer } from './Text';
import { PlatformAudioRenderer } from './Audio';
import { PlatformImageRenderer } from './Image';
import { PlatformVideoRenderer } from './Video';

export function PlatformComponentRenderer(props: ComponentRendererProps) {
  if (props.node.component.ctype != 'PLATFORM' || props.isDocked) {
    return null;
  }
  let component: JSX.Element;
  switch (props.node.component.dtype) {
    case 'TEXT':
      component = <PlatformTextRenderer params={props.node.params} />;
      break;
    case 'VIDEO':
      component = <PlatformVideoRenderer params={props.node.params} />;
      break;
    case 'IMAGE':
      component = <PlatformImageRenderer params={props.node.params} />;
      break;
    case 'AUDIO':
      component = <PlatformAudioRenderer params={props.node.params} />;
      break;
  }
  return <Bubble type="PLATFORM">{component}</Bubble>;
}
