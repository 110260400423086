import { PlatformAudioComponent } from '@karya/core';
import { ParsedParams } from 'src/helpers/task-previews/Types';
import { AudioRenderer } from 'src/components/TaskBuilder/Preview/common/Audio';

export function PlatformAudioRenderer({ params }: { params?: ParsedParams<any> }) {
  // @ts-expect-error
  const typedParams = params as ParsedParams<PlatformAudioComponent>;

  return (
    <AudioRenderer
      src={typedParams?.recording}
      start={typedParams.start ?? undefined}
      end={typedParams.end ?? undefined}
    />
  );
}
