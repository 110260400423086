import { DatasetShape, NgGenericTask, TaskParams } from '@karya/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { metadataSection } from 'src/components/TaskBuilder/FormParameters/Basic';
import { generateComponentParamSections } from 'src/components/TaskBuilder/FormParameters/Generate';
import { SpecKind, useGenerateSpec } from 'src/forms/tasks/GenerateSpec';
import { useForm } from 'src/helpers/parameter-renderer/hooks';
import { useSpecShape } from './SpecShape';

export type TaskBuilderState = {
  spec: NgGenericTask;
  setSpec: (spec: NgGenericTask) => void;
  kind: SpecKind;
  setHasError?: (hasError: boolean) => void;
  taskParams: Partial<TaskParams>;
  datasetShape?: DatasetShape;
};

export function useTaskBuilder({ spec, setSpec, taskParams, kind, datasetShape }: TaskBuilderState) {
  const [formParameters, setFormParameters] = useState([metadataSection]);
  const generateSpec = useGenerateSpec({
    taskParams,
    kind,
    datasetShape,
    setSpec,
    spec,
  });

  // All the references in the spec
  const references = useMemo<Array<string>>(() => {
    const components = Object.values(spec);
    const referencesList = components.flatMap((component) => {
      if (component.next.src !== 'CONSTANT') {
        return Object.values(component.next.options);
      } else {
        return component.next.value!;
      }
    });
    const uniqueReferences = Array.from(new Set(referencesList));
    return uniqueReferences;
  }, [spec]);

  // All the dangling references in the spec
  const danglingReferences = useMemo<Array<string>>(() => {
    const availableKeys = new Set(Object.keys(spec));
    return references.filter((referredKey) => !availableKeys.has(referredKey) && referredKey !== null);
  }, [spec]);

  // All the unreffered keys
  const unreferredKeys = useMemo<Array<string>>(() => {
    const availableKeys = Object.keys(spec);
    const referencesSet = new Set(references);
    return availableKeys.filter((key) => !referencesSet.has(key) && key !== 'start');
  }, [spec]);

  // Get output shape
  const outputShape = useSpecShape(spec);

  // Errors Exist
  const hasErrors = danglingReferences.length > 0 || unreferredKeys.length > 0 || !spec.start || !spec.end;

  /**
   * Delete component with key from the task specification
   * @param key Key of the component to delete
   */
  const deleteComponentWithKey = useCallback(
    (key: string) => {
      const { [key]: _, ...updatedSpec } = spec;
      setSpec(updatedSpec as NgGenericTask);
    },
    [spec]
  );

  /**
   * Add component to task specification
   * @param key Key of the component to delete
   */
  const addComponentToSpec = useCallback(
    (component: NgGenericTask[string]) => {
      if (component.ctype === 'END') {
        component.next = {
          type: 'REF',
          src: 'CONSTANT',
          value: null,
        };
      }
      const updatedSpec: NgGenericTask = {
        ...spec,
        [component.key]: component,
      };
      setSpec(updatedSpec);
      componentForm.resetForm();
    },
    [spec]
  );

  const componentForm = useForm({
    parameters: formParameters,
    onSubmit: addComponentToSpec,
    isKeyPath: true,
  });

  useEffect(() => {
    const updatedFormParameters = generateComponentParamSections(
      componentForm.ctx.form,
      spec,
      datasetShape,
      outputShape
    );
    setFormParameters(updatedFormParameters);
  }, [componentForm.ctx.form, datasetShape, outputShape]);

  /**
   * Reset form and set the component builder state to start component
   */
  const setBuilderToStart = useCallback(() => {
    componentForm.resetForm();
    componentForm.setFormField('ctype', 'START');
  }, []);

  /**
   * Edit component with key
   */
  const editComponentWithKey = useCallback(
    (key: string) => {
      componentForm.resetForm();
      componentForm.setForm(spec[key] as any);
    },
    [spec]
  );

  /**
   * Reset form and set the component builder state to end component
   */
  const setBuilderToEnd = useCallback(() => {
    componentForm.resetForm();
    componentForm.setFormField('ctype', 'END');
  }, []);

  /**
   * Reset form and set the component builder state to end component
   */
  const setBuilderToAccepted = useCallback(() => {
    componentForm.setFormField('name', 'Validation Component');
    componentForm.setFormField(
      'description',
      `Validate the datapoint and set the 'accepted' boolean field in assignment output`
    );
    componentForm.setFormField('ctype', 'USER');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('dtype', 'BOOLEAN');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('selected.key', 'accepted');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('true_label.value', 'Accept');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('true_label.src', 'CONSTANT');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('false_label.value', 'Reject');

    // HACK: type acrobatics required to remove this
    // @ts-expect-error
    componentForm.setFormField('false_label.src', 'CONSTANT');
  }, []);

  /**
   * Reset form and set the component key to reference
   * @param ref key of the component you want to create
   */
  const setBuilderToRef = useCallback((ref: string) => {
    if (ref === 'end') {
      setBuilderToEnd();
      return;
    } else {
      componentForm.setFormField('key', ref);
    }
  }, []);

  return {
    spec,
    danglingReferences,
    unreferredKeys,
    componentForm,
    hasErrors,
    actions: {
      setBuilderToStart,
      setBuilderToEnd,
      setBuilderToAccepted,
      setBuilderToRef,
      deleteComponentWithKey,
      editComponentWithKey,
    },
    generateSpec,
  };
}
