import { ComponentRendererProps } from '../ComponentRenderer';
import { AudioRenderer } from '../common/Audio';
import { UserTextRenderer } from './Text';
import { Bubble } from '../Bubble';
import { UserBooleanRenderer } from './Boolean';
import { UserMCQRenderer } from './MCQ';
import { useEffect } from 'react';
import { UserAudioSegmentRenderer } from './AudioSegmentation';
import { UserImageRenderer } from './Image';
import { UserVideoRenderer } from './Video';

const dockableComponents = ['TEXT'];
const autoNextComponents = ['VIDEO', 'IMAGE', 'AUDIO', 'AUDIO_SEGMENTATION', 'AUDIO_MARKER'];

export function UserComponentRenderer(props: ComponentRendererProps) {
  if (props.node.component.ctype != 'USER') {
    return null;
  }

  const dtype = props.node.component.dtype;
  if (props.isCurrent && dockableComponents.includes(dtype) !== props.isDocked) {
    return null;
  }
  useEffect(() => {
    if (props.isCurrent && autoNextComponents.includes(dtype)) {
      props.next();
    }
  }, [props.isCurrent]);

  let component: JSX.Element;
  switch (dtype) {
    case 'TEXT':
      component = <UserTextRenderer {...props} />;
      break;
    case 'VIDEO':
      component = <UserVideoRenderer />;
      break;
    case 'IMAGE':
      component = <UserImageRenderer />;
      break;
    case 'AUDIO':
    case 'AUDIO_MARKER':
      component = <AudioRenderer />;
      break;
    case 'AUDIO_SEGMENTATION':
      component = <UserAudioSegmentRenderer {...props} />;
      break;
    case 'BOOLEAN':
      component = <UserBooleanRenderer {...props} />;
      break;
    case 'MCQ':
      component = <UserMCQRenderer {...props} />;
  }
  return <Bubble type="USER">{component}</Bubble>;
}
