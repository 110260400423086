import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { LanguageCode, TaskParams, languagesList } from '@karya/core';
import { ParameterArray, ParameterDefinition, ParameterSection } from '@karya/parameter-specs';
import React, { useEffect, useMemo } from 'react';
import { ChakraFormRenderer } from 'src/helpers/parameter-renderer/backends';
import { InputContainer } from 'src/helpers/parameter-renderer/backends/chakra/components/FormInputContainer';
import { FormChangedHook, useForm } from 'src/helpers/parameter-renderer/hooks';
import { FormInputRenderer } from 'src/helpers/parameter-renderer/types/CustomRenderer';
import { ButtonVariant } from 'src/themes/ButtonVariants';
import { KaryaColor } from 'src/themes/Colors';

export type Localizations = Record<LanguageCode | 'DEFAULT', string>;
export const DEFAULT_LOCALE_CODE = 'DEFAULT';

function useLocalizationFormParameteterDefinitions(
  _parameter: ParameterDefinition<keyof TaskParams>,
  languages: Array<LanguageCode> | undefined,
) {
  const form = useMemo(() => {
    if (!languages) return [];
    const langCodes: (keyof Localizations)[] = [...languages];

    if (!langCodes.includes(DEFAULT_LOCALE_CODE)) {
      langCodes.unshift(DEFAULT_LOCALE_CODE);
    }

    const localizationParameters: ParameterArray<Localizations> = langCodes.map((code) => ({
      id: code,
      label: code === 'DEFAULT' ? 'Default' : languagesList[code],
      description: `Text to be dislayed in ${code === 'DEFAULT' ? 'Default Language' : languagesList[code]}`,
      type: 'string',
      required: true,
    }));

    const section: ParameterSection<Localizations> = {
      label: ``,
      description: ``,
      parameters: localizationParameters,
      required: true,
    };

    const form = [section];

    return form;
  }, [languages]);
  return form;
}

export const LocalizeStringRenderer: FormInputRenderer<{}, {}, TaskParams> = (props) => {
  const disclosure = useDisclosure({});

  // Setup the localization form
  const localizationFormParameters = useLocalizationFormParameteterDefinitions(props.parameter, props.form.localizeTo);
  const localizeFormChangeHook: FormChangedHook<Localizations> = (_id, control) => {
    props.ctx.setFormField(props.parameter.id, control.ctx.form);
  };

  let initialLocalizedString = props.ctx.form[props.parameter.id] ?? {};

  console.log('initialLocalizedString', initialLocalizedString);
  if (typeof initialLocalizedString === 'string') {
    initialLocalizedString = { [DEFAULT_LOCALE_CODE]: initialLocalizedString };
  }

  const localizeForm = useForm({
    parameters: localizationFormParameters,
    formChangedHook: localizeFormChangeHook,
    initialState: initialLocalizedString,
  });

  // Check if any of the required fields are missing
  const hasMissingFields = useMemo(() => {
    return Object.values(localizeForm.ctx.form).some((value) => value === undefined || value === '');
  }, [localizeForm.ctx.form]);

  // Manage state for default string
  const defaultString = localizeForm.ctx.form[DEFAULT_LOCALE_CODE] ?? '';
  const handleDefaultStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    localizeForm.ctx.setFormField(DEFAULT_LOCALE_CODE, e.target.value);
  };

  return (
    <React.Fragment>
      <InputContainer {...props.inputProps}>
        <Flex gap="10px">
          <Input value={defaultString} onChange={handleDefaultStringChange} />
          <Box>
            <Button
              variant={ButtonVariant.secondary}
              backgroundColor={!props.isDisabled && hasMissingFields ? KaryaColor.red[600] : undefined}
              rightIcon={<ChevronRightIcon />}
              onClick={disclosure.onOpen}
              isDisabled={props.isDisabled || !props.form.localizeTo || props.form.localizeTo.length == 0}
              px="5px"
            >
              Localize {props.parameter.label}
            </Button>
          </Box>
        </Flex>
      </InputContainer>

      <Modal {...disclosure} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">
            <Text>{`Localize ${props.parameter.label}`}</Text>
            <Text fontSize="sm" color="gray.500">
              Provide translations for "{props.parameter.label}" in these languages
            </Text>
          </ModalHeader>
          <ModalCloseButton top="1rem" />

          <ModalBody>
            <ChakraFormRenderer ctx={localizeForm.ctx} />
          </ModalBody>

          <ModalFooter>
            <Button variant={ButtonVariant.submit} onClick={disclosure.onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
