import {
  DatasetShape,
  getComponentParameters,
  GTParamsArray,
  GTParamsDefination,
  InputSource,
  inputSources,
  NgGenericTask,
  NgUnitComponent,
} from '@karya/core';
import { ParameterArray, ParameterDefinition, ParameterSection } from '@karya/parameter-specs';

import {
  endComponentMetadataParameters,
  localFunctionType,
  loopComponentLtype,
  metadataSection,
  nextParam,
  platformDType,
  startComponentMetadataParameters,
  userDType,
} from './Basic';

type ComponentBuilderState = {
  ctype?: NgGenericTask[string]['ctype'];
  dtype?: Extract<NgGenericTask[string], { ctype: 'PLATFORM' | 'USER' }>['dtype'];
  function_id?: Extract<NgGenericTask[string], { ctype: 'BACKGROUND_LOCAL' }>['function_id'];
  ltype?: Extract<NgGenericTask[string], { ctype: 'LOOP' }>['ltype'];
  [key: string]: any;
};
export function generateComponentParamSections(
  state: ComponentBuilderState,
  spec: NgGenericTask,
  datasetSpape?: DatasetShape,
  outputShape?: DatasetShape
) {
  const baseSection = structuredClone(metadataSection) as typeof metadataSection;
  const { ctype, dtype, ltype, function_id, name: _name, description: _description } = state;
  if (!ctype) {
    return [baseSection];
  }
  switch (ctype) {
    case 'USER':
      baseSection.parameters.push(userDType);
      break;
    case 'PLATFORM':
      baseSection.parameters.push(platformDType);
      break;

    case 'LOOP': {
      baseSection.parameters.push(loopComponentLtype);
      break;
    }
    case 'BACKGROUND_LOCAL':
      baseSection.parameters.push(localFunctionType);
      break;
    case 'START': {
      baseSection.parameters = startComponentMetadataParameters;
      break;
    }
    case 'END': {
      baseSection.parameters = endComponentMetadataParameters;
      break;
    }
  }

  let genericTaskParameters: GTParamsArray = getGTParamsArray({
    ctype,
    dtype,
    function_id,
    ltype,
  });
  const componentParamSections = genericTaskParameters.map((param) => {
    return converGtParamsToSections(param, state, spec, datasetSpape, outputShape);
  });

  const formDef = [baseSection, ...componentParamSections];
  if (genericTaskParameters.length !== 0 || ctype === 'START') {
    formDef.push(converGtParamsToSections(nextParam, state, spec, datasetSpape, outputShape));
  }

  return formDef;
}

export function getGTParamsArray({ ctype, dtype, function_id, ltype }: ComponentBuilderState) {
  try {
    // @ts-expect-error
    return getComponentParameters({ ctype, dtype, function_id, ltype });
  } catch (err) {
    return [];
  }
}

// TODO: Refactor, cleanup and document this fucntion
function converGtParamsToSections(
  paramDef: GTParamsDefination<any, any>,
  state: ComponentBuilderState,
  spec: NgGenericTask,
  datasetSpape?: DatasetShape,
  outputShape?: DatasetShape
) {
  const getId = (type: 'src' | 'key' | 'value' | 'options' | 'itype' | 'componentKey' | string) =>
    `${paramDef.id}.${type}`;
  const getDescription = (type: 'Source' | 'Key' | 'Value' | string) =>
    type === 'Value'
      ? `Set ${paramDef.label} to a constant value`
      : `Refers to the ${type} where ${paramDef.label} is stored`;
  const currSource = state[getId('src')] as InputSource;

  const section: ParameterSection<any> = {
    label: paramDef.label,
    description: paramDef.description,
    parameters: [],
    required: true,
  };

  const stringKeyDef: ParameterDefinition<any> = {
    id: getId('key'),
    description: getDescription('Key'),
    label: `Location of ${paramDef.label}`,
    type: 'string',
    required: true,
  };

  let dataKeyDef: ParameterDefinition<any> = stringKeyDef;
  let fileKeyDef: ParameterDefinition<any> = stringKeyDef;
  let suggestedKeyDef: ParameterDefinition<any> | undefined;

  let shapeHint: DatasetShape | undefined;
  const suggestionsAllowedSources = ['MT_INPUT', 'MTA_OUTPUT'];
  if (currSource === 'MTA_OUTPUT') {
    shapeHint = outputShape;
  } else if (currSource === 'MT_INPUT') {
    shapeHint = datasetSpape;
  }

  const shapeHintAvailable =
    !!(shapeHint?.data.length || shapeHint?.files.length) && suggestionsAllowedSources.includes(currSource);
  if (shapeHint && shapeHintAvailable) {
    const dataKeyList = Object.fromEntries(shapeHint.data.map((key) => [key, key]));
    const fileKeyList = Object.fromEntries(shapeHint.files.map((key) => [key, key]));
    dataKeyDef = {
      id: getId('key'),
      label: `Suggested Location for ${paramDef.label}`,
      description: getDescription('Key'),
      type: 'enum',
      list: dataKeyList,
      required: true,
    };
    fileKeyDef = {
      id: getId('key'),
      label: `Suggested Location for ${paramDef.label}`,
      description: getDescription('Key'),
      type: 'enum',
      list: fileKeyList,
      required: true,
    };
    suggestedKeyDef = paramDef.paramType === 'FILE' ? fileKeyDef : dataKeyDef;
  }

  // Source parameter definition
  const srcDef: ParameterDefinition<any> = {
    id: getId('src'),
    label: 'Source',
    description: `${paramDef.label} Source`,
    type: 'enum',
    list: inputSources,
    required: paramDef.required,
  };

  // Type parameter definition
  const typeDef: ParameterDefinition<any> = {
    id: `${paramDef.id}.type`,
    label: 'Parameter Type',
    type: 'fixed',
    value: paramDef.paramType,
    required: false,
  };

  const paramInUse = state[getId('src')] || state[getId('key')];
  if (paramDef.paramType !== 'SPEC' && paramInUse) {
    section.parameters.push(typeDef);
  }

  // If the param is an output param, push the key definition and return
  if (paramDef.type === 'output') {
    section.parameters.push(stringKeyDef);
    return section;
  }

  const isBoolean = paramDef.type === 'boolean';
  // @ts-expect-error
  const valueDef: ParameterDefinition<any> = {
    id: getId('value'),
    label: isBoolean ? paramDef.label : 'Value',
    description: getDescription('Value'),
    type: paramDef.type,
    // @ts-expect-error
    value: paramDef.value,
    // @ts-expect-error
    list: paramDef.list,
    required: true,
  };

  //===========================================
  // Handle SPEC
  //===========================================
  if (paramDef.paramType === 'SPEC') {
    section.parameters.push({
      id: paramDef.id,
      label: 'Upload Specification',
      description: 'Create specification',
      type: 'json_object',
      required: true,
      renderer: 'task-builder-modal',
    });
    return section;
  }

  //===========================================
  // Handle Input Parameters
  //===========================================

  // Push paramter definition for src if the param is an input param
  section.parameters.push(srcDef);

  // We don't want to show the key and value fields if the source is not defined
  if (!currSource) {
    return section;
  }

  if (paramDef.type === 'enum' && currSource) {
    const enumOptions: ParameterDefinition<any> = {
      id: getId('options'),
      label: 'Enum Options',
      type: 'fixed',
      value: paramDef.list,
      required: false,
    };
    section.parameters.push(enumOptions);
  }

  if (currSource === 'CONSTANT') {
    // HACK: Set type to 'file' for constant file param to allow for upload
    if (paramDef.paramType === 'FILE') {
      section.parameters.push({ ...valueDef, type: 'file' });
    } else {
      section.parameters.push(valueDef);
    }
    return section;
  }

  if (paramDef.paramType !== 'REF') {
    if (suggestedKeyDef) {
      section.parameters.push(suggestedKeyDef);
    }
    section.parameters.push(stringKeyDef);
    return section;
  }

  // If the current param is a REF component
  const itypeDef: ParameterDefinition<any> = {
    id: getId('itype'),
    label: 'Branching component',
    description: 'The type of component whose output which is to be used to evaluate the next component',
    type: 'enum',
    list: {
      BOOLEAN: 'Boolean',
      STRING: 'String',
      STRING_ARRAY: 'Multiple Choice',
    },
    required: paramDef.required,
  };
  section.parameters.push(itypeDef);

  if (!state[getId('itype')]) {
    return section;
  }

  const getBranchDef = (branch: string | boolean) => {
    const branchAsStr = branch.toString();
    const branchName = branchAsStr.charAt(0).toUpperCase() + branchAsStr.slice(1);
    const branchDef: ParameterDefinition<any> = {
      id: `${getId('options')}.${branch}`,
      label: `${branchName} Reference`,
      description: `Key referencing the next component for branch - '${branchAsStr}'`,
      type: 'string',
      required: true,
    };
    return branchDef;
  };

  // If the current param uses a mcq component as base
  const mcqComponentEntries = Object.values<Extract<NgUnitComponent, { dtype: 'MCQ' }>>(spec as any)
    .filter((component) => component.ctype === 'USER' && component.dtype === 'MCQ')
    .filter((component) => component.options.src === 'CONSTANT')
    .map((component) => [component.key, component.name ?? component.key]);

  // If the current component is a CONSTANT MCQ component with options set insert it as the first option
  const currDtype = state['dtype'];
  const currComponentKey = state['key'];
  const currOptionsSrc = state['options.src'];
  const currOptionsValue: string[] = state['options.value'];
  const currSelectedOutputKey = state['selected.key'];
  const isCurrConstantMCQBranchable =
    currDtype === 'MCQ' &&
    currOptionsSrc === 'CONSTANT' &&
    currOptionsValue &&
    currComponentKey &&
    currSelectedOutputKey;
  if (isCurrConstantMCQBranchable && currSource === 'MTA_OUTPUT') {
    mcqComponentEntries.unshift(['__current__', 'Current MCQ Component']);
  }

  mcqComponentEntries.push(['__custom__', 'Custom Branches (Specify)']);

  const componentSelectDef: ParameterDefinition<any> = {
    id: getId('componentKey'),
    label: 'Base MCQ Component',
    description: 'Multiple Choice component to use for branching',
    type: 'enum',
    list: Object.fromEntries(mcqComponentEntries),
    required: true,
  };

  const variableKeyDef: any = {
    id: getId('key'),
    label: 'Test Variable Key',
    description: `Refers to the location within '${inputSources[currSource]}' that you want to use for branching`,
    type: 'string',
    required: true,
  };

  const customBranchesDef: ParameterDefinition<any> = {
    id: getId('__next_branches'),
    label: 'Specify Branches',
    description: 'Specify the values of the test variables that you want to use for branching',
    type: 'list',
    required: true,
  };

  const customBranches: string[] | undefined = state[getId('__next_branches')];
  const customReferencesDefs: ParameterArray = customBranches?.map((branch) => getBranchDef(branch)) ?? [];

  switch (state[getId('itype')]) {
    case 'BOOLEAN': {
      section.parameters.push(variableKeyDef, getBranchDef(true), getBranchDef(false));
      break;
    }
    case 'STRING_ARRAY':
      section.parameters.push(componentSelectDef);
      const selectedComponentKey = state[getId('componentKey')];

      if (!selectedComponentKey) {
        break;
      }
      if (selectedComponentKey === '__custom__') {
        section.parameters.push(variableKeyDef, customBranchesDef, ...customReferencesDefs);
        break;
      }
      const selectedComponentExistsInSpec = spec.hasOwnProperty(selectedComponentKey);

      variableKeyDef.type = 'fixed';
      variableKeyDef.required = false;
      let branchDefs: ParameterArray;
      if (isCurrConstantMCQBranchable && selectedComponentKey === '__current__') {
        branchDefs = currOptionsValue.map((branch) => getBranchDef(branch));
        variableKeyDef.value = currComponentKey;
      } else if (selectedComponentExistsInSpec) {
        const branchBase = spec[selectedComponentKey] as Extract<NgUnitComponent, { dtype: 'MCQ' }>;
        // @ts-expect-error - Work around type errors
        branchDefs = branchBase.options.value.map((branch) => getBranchDef(branch));
        variableKeyDef.value = branchBase.selected.key;
      } else {
        break;
      }

      section.parameters.push(variableKeyDef, ...branchDefs);
      break;
    case 'STRING': {
      section.parameters.push(variableKeyDef, customBranchesDef, ...customReferencesDefs);
      break;
    }
  }

  return section;
}
